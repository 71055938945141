import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("div", {
    staticClass: "box-record"
  }, [_c("div", {
    staticClass: "table-record-header el-table",
    class: {
      sticky: _vm.isSticky
    },
    style: {
      width: "calc(100% - ".concat(_vm.isSticky ? !_vm.isCollapse ? "280px" : "114px" : "0px", ")")
    }
  }, [_c("div", {
    staticStyle: {
      width: "2%",
      "text-align": "center"
    }
  }, [_c("el-checkbox", {
    attrs: {
      label: ""
    },
    on: {
      change: _vm.checkAll
    }
  })], 1), _c("div", {
    staticStyle: {
      width: "2%"
    }
  }), _c("div", {
    class: _vm.getOrderClass("label"),
    staticStyle: {
      width: "15%"
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.table-header-record-line"))), _c("span", {
    staticClass: "caret-wrapper",
    on: {
      click: function click($event) {
        return _vm.setOrderBy("label");
      }
    }
  }, [_c("i", {
    staticClass: "sort-caret ascending"
  }), _c("i", {
    staticClass: "sort-caret descending"
  })])]), _c("div", {
    class: _vm.getOrderClass("type"),
    staticStyle: {
      width: "15%"
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.table-header-record-type"))), _c("span", {
    staticClass: "caret-wrapper",
    on: {
      click: function click($event) {
        return _vm.setOrderBy("type");
      }
    }
  }, [_c("i", {
    staticClass: "sort-caret ascending"
  }), _c("i", {
    staticClass: "sort-caret descending"
  })])]), _c("div", {
    class: _vm.getOrderClass("value"),
    staticStyle: {
      width: "21%"
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.table-header-record-value"))), _c("span", {
    staticClass: "caret-wrapper",
    on: {
      click: function click($event) {
        return _vm.setOrderBy("value");
      }
    }
  }, [_c("i", {
    staticClass: "sort-caret ascending"
  }), _c("i", {
    staticClass: "sort-caret descending"
  })])]), _c("div", {
    class: _vm.getOrderClass("weight"),
    staticStyle: {
      width: "11%"
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.table-header-record-weight"))), _c("span", {
    staticClass: "caret-wrapper",
    on: {
      click: function click($event) {
        return _vm.setOrderBy("weight");
      }
    }
  }, [_c("i", {
    staticClass: "sort-caret ascending"
  }), _c("i", {
    staticClass: "sort-caret descending"
  })])]), _c("div", {
    class: _vm.getOrderClass("ttl"),
    staticStyle: {
      width: "11%"
    }
  }, [_vm._v("TTL"), _c("span", {
    staticClass: "caret-wrapper",
    on: {
      click: function click($event) {
        return _vm.setOrderBy("ttl");
      }
    }
  }, [_c("i", {
    staticClass: "sort-caret ascending"
  }), _c("i", {
    staticClass: "sort-caret descending"
  })])]), _c("div", {
    staticStyle: {
      width: "13%",
      "text-align": "left"
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.table-header-record-hc")))]), _c("div", {
    staticStyle: {
      width: "10%",
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm.$t("domain.table-header-record-opr")))])]), _c("div", {
    class: {
      "sticky-block": _vm.isSticky
    }
  }), _c("div", {
    staticClass: "table-record-content"
  }, _vm._l(_vm.domainRecords, function (row, idx) {
    return _c("div", {
      key: idx,
      staticClass: "box-table"
    }, [_c("div", {
      staticClass: "table-domain-header"
    }, [_c("div", {
      staticClass: "table-domain-col",
      staticStyle: {
        width: "2%",
        "text-align": "center"
      }
    }, [_c("el-checkbox", {
      attrs: {
        label: ""
      },
      model: {
        value: row.checked,
        callback: function callback($$v) {
          _vm.$set(row, "checked", $$v);
        },
        expression: "row.checked"
      }
    })], 1), _c("div", {
      staticStyle: {
        width: "2%",
        "text-align": "left"
      }
    }, [!row.isNew ? _c("div", [row.domain == _vm.zoneFqName ? _c("el-tooltip", {
      attrs: {
        content: _vm.$t("domain.table-tip-key"),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("i", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: row.domain == _vm.zoneFqName,
        expression: "row.domain==zoneFqName"
      }],
      staticClass: "icon-key1 primary-domain"
    })]) : _c("el-tooltip", {
      staticClass: "item",
      attrs: {
        effect: "dark",
        content: _vm.formatStatus(row.status),
        placement: "top",
        "open-delay": 500
      }
    }, [row.status == 1 ? _c("span", {
      staticClass: "icon-dot_single domain-status-pause"
    }) : _c("span", {
      staticClass: "icon-dot_single domain-status-normal"
    })])], 1) : _vm._e()]), _c("div", {
      staticClass: "table-domain-col",
      staticStyle: {
        width: "51%"
      }
    }, [row.isNew ? _c("div", [_c("el-input", {
      directives: [{
        name: "focus",
        rawName: "v-focus"
      }],
      attrs: {
        placeholder: _vm.$t("domain.dlg-input-domain"),
        clearable: ""
      },
      model: {
        value: row.domain,
        callback: function callback($$v) {
          _vm.$set(row, "domain", typeof $$v === "string" ? $$v.trim() : $$v);
        },
        expression: "row.domain"
      }
    }, [_c("template", {
      slot: "append"
    }, [_vm._v(_vm._s(_vm.zoneSuffixName))])], 2)], 1) : _c("div", [_vm._v(" " + _vm._s(_vm._f("formatDomain")(row.domain)) + " ")])]), _c("div", {
      staticClass: "btn-domain-save",
      staticStyle: {
        width: "14%",
        "text-align": "right"
      }
    }, [_c("div", {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: row.isEdit,
        expression: "row.isEdit"
      }]
    }, [_c("el-button", {
      directives: [{
        name: "sec",
        rawName: "v-sec",
        value: ["/domains/:domain@POST"],
        expression: "['/domains/:domain@POST']"
      }],
      staticClass: "edit-btn",
      attrs: {
        type: "primary",
        round: ""
      },
      on: {
        click: function click($event) {
          return _vm.saveRecord(row);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("common.button.save")))]), _c("el-button", {
      staticClass: "edit-btn",
      attrs: {
        type: "info",
        round: ""
      },
      on: {
        click: function click($event) {
          return _vm.cancelEdit(row, idx);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))])], 1)]), _c("div", {
      staticClass: "table-domain-col domain-opr-col",
      staticStyle: {
        width: "31%"
      }
    }, [_c("el-tooltip", {
      attrs: {
        content: _vm.$t("domain.table-icon-add-record"),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [_c("el-button", {
      directives: [{
        name: "sec",
        rawName: "v-sec",
        value: ["/domains/:domain@POST"],
        expression: "['/domains/:domain@POST']"
      }],
      staticStyle: {
        "font-size": "14px"
      },
      attrs: {
        type: "text",
        icon: "icon-plus2"
      },
      on: {
        click: function click($event) {
          return _vm.addRow(row);
        }
      }
    })], 1)]), _c("el-tooltip", {
      attrs: {
        content: _vm.domainStatusBtnTip(row),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [_c("el-button", {
      directives: [{
        name: "sec",
        rawName: "v-sec",
        value: ["/domains/updatedomainstatus@PUT"],
        expression: "['/domains/updatedomainstatus@PUT']"
      }],
      attrs: {
        type: "text",
        icon: row.status == 0 ? "icon-pause1" : "icon-play",
        disabled: _vm.zoneStatus == 2 || row.isPrimaryDomain || row.isNew || row.isEdit
      },
      on: {
        click: function click($event) {
          return _vm.handleMenuCommand({
            flag: "status",
            row: row
          });
        }
      }
    })], 1)]), _c("el-tooltip", {
      attrs: {
        content: _vm.$t("common.button.clone"),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [_c("el-button", {
      directives: [{
        name: "sec",
        rawName: "v-sec",
        value: ["/domains/:domain@POST"],
        expression: "['/domains/:domain@POST']"
      }],
      attrs: {
        type: "text",
        icon: "el-icon-copy-document",
        disabled: row.domain == _vm.zoneFqName || row.isNew || row.isEdit
      },
      on: {
        click: function click($event) {
          return _vm.cloneRow(row);
        }
      }
    })], 1)]), _c("el-tooltip", {
      attrs: {
        content: row.remark ? row.remark : _vm.$t("domain.add-button-remark"),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [_c("el-button", {
      directives: [{
        name: "sec",
        rawName: "v-sec",
        value: ["/domainindex/remark/:domain@PUT"],
        expression: "['/domainindex/remark/:domain@PUT']"
      }],
      class: row.remark ? "" : "no-data",
      staticStyle: {
        "font-size": "20px"
      },
      attrs: {
        type: "text",
        icon: row.remark ? "icon-file-text2" : "icon-file-empty",
        disabled: row.isNew || row.isEdit
      },
      on: {
        click: function click($event) {
          return _vm.addRemark(row);
        }
      }
    })], 1)]), _c("el-tooltip", {
      attrs: {
        content: _vm.$t("common.msg.show-queries"),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [_c("el-button", {
      attrs: {
        type: "text",
        icon: "el-icon-s-data",
        disabled: row.isNew
      },
      on: {
        click: function click($event) {
          return _vm.openQueryDialog(row);
        }
      }
    })], 1)]), _c("el-tooltip", {
      attrs: {
        content: _vm.$t("domain.table-icon-hc") + ": " + _vm.getStatus(row),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [row.hc_status == 1 ? _c("img", {
      staticStyle: {
        width: "22px",
        "vertical-align": "middle",
        margin: "0 11.5px"
      },
      attrs: {
        src: require("@/assets/systemtask.png"),
        alt: _vm.$t("domain.table-icon-hc") + ": " + _vm.getStatus(row),
        title: _vm.$t("domain.table-icon-hc") + ": " + _vm.getStatus(row)
      }
    }) : _c("el-button", {
      class: row.hc_status == 0 ? "hc-monitor" : "no-data",
      attrs: {
        type: "text",
        icon: "icon-hc_monitor",
        disabled: row.hc_status == -1 || row.isNew
      },
      on: {
        click: function click($event) {
          return _vm.showHcTask(row);
        }
      }
    })], 1)]), _c("el-tooltip", {
      attrs: {
        content: _vm.$t("common.button.delete"),
        placement: "top",
        "open-delay": 500
      }
    }, [_c("div", [_c("el-button", {
      directives: [{
        name: "sec",
        rawName: "v-sec",
        value: ["/domains/:domain@DELETE"],
        expression: "['/domains/:domain@DELETE']"
      }],
      attrs: {
        type: "text",
        icon: "el-icon-delete",
        disabled: row.domain == _vm.zoneFqName || row.isNew || row.isEdit
      },
      on: {
        click: function click($event) {
          return _vm.deleteDomain(row);
        }
      }
    })], 1)]), _c("el-dropdown", {
      class: {
        "is-disabled": row.isNew
      },
      on: {
        command: _vm.handleMenuCommand
      }
    }, [_c("span", {
      staticClass: "el-dropdown-link"
    }, [_c("i", {
      staticClass: "el-icon-more btn-record-more"
    })]), _c("el-dropdown-menu", {
      attrs: {
        slot: "dropdown"
      },
      slot: "dropdown"
    }, [_c("el-tooltip", {
      attrs: {
        content: _vm.domainHcStatusBtnTip(row),
        placement: "top",
        "open-delay": 500,
        disabled: row.status == 0 && _vm.zoneStatus != 2
      }
    }, [_c("div", [row.hc_status == 0 || row.hc_status == -1 ? _c("el-dropdown-item", {
      attrs: {
        command: _vm.wrapCommandData("hc", row),
        disabled: row.hc_status == -1 || _vm.zoneStatus == 2 || row.status == 1 || row.isEdit
      }
    }, [_vm._v(_vm._s(_vm.$t("domain.table-menu-hc-disable")))]) : _c("el-dropdown-item", {
      attrs: {
        command: _vm.wrapCommandData("hc", row),
        disabled: _vm.zoneStatus == 2 || row.status == 1 || row.isEdit
      }
    }, [_vm._v(_vm._s(_vm.$t("domain.table-menu-hc-enable")))])], 1)])], 1)], 1)], 1)]), _c("div", {
      staticClass: "table-record-list"
    }, _vm._l(_vm.filterRecordsBySearch(row.records), function (record, ridx) {
      return _c("div", {
        key: ridx
      }, [!record.isEdit || row.isPrimaryDomain && record.type == "NS" ? _c("div", {
        staticClass: "table-record-row",
        class: {
          "record-primary": row.isPrimaryDomain && record.type == "NS"
        }
      }, [_c("div", {
        staticStyle: {
          width: "2%"
        }
      }), _c("div", {
        staticStyle: {
          width: "2%"
        }
      }, [_c("el-tooltip", {
        staticClass: "item",
        attrs: {
          effect: "dark",
          content: _vm.formatRecordStatus(row.status, record.status),
          placement: "top",
          "open-delay": 500
        }
      }, [row.status == 1 ? _c("span", {
        staticClass: "icon-dot_single domain-status-pause"
      }) : record.status == 2 ? _c("span", {
        staticClass: "icon-dot_single domain-status-warning"
      }) : _c("span", {
        staticClass: "icon-dot_single domain-status-normal"
      })])], 1), _c("div", {
        staticStyle: {
          width: "15%"
        }
      }, [_c("div", {
        staticClass: "record-wrap"
      }, [_c("el-tooltip", {
        staticClass: "item",
        attrs: {
          placement: "right",
          "open-delay": 500,
          disabled: !record.lineTip
        }
      }, [_c("div", {
        staticClass: "record-tip",
        staticStyle: {
          "max-width": "500px"
        },
        attrs: {
          slot: "content"
        },
        slot: "content"
      }, [_vm._v(_vm._s(record.label))]), _c("div", {
        staticClass: "tag-line",
        class: {
          "record-can-edit": !(row.isPrimaryDomain && record.type == "NS")
        },
        on: {
          mouseenter: function mouseenter($event) {
            return _vm.showRecordTip($event, record, "lineTip");
          },
          click: function click($event) {
            return _vm.recordEdit(row, record);
          }
        }
      }, [_vm._v(_vm._s(record.label))])])], 1)]), _c("div", {
        staticStyle: {
          width: "15%"
        }
      }, [_c("div", {
        staticClass: "record-wrap"
      }, [_c("div", {
        class: {
          "record-can-edit": !(row.isPrimaryDomain && record.type == "NS")
        },
        on: {
          click: function click($event) {
            return _vm.recordEdit(row, record);
          }
        }
      }, [_vm._v(_vm._s(record.type))])])]), _c("div", {
        staticStyle: {
          width: "21%"
        }
      }, [_c("div", {
        staticClass: "record-wrap"
      }, [_c("el-tooltip", {
        staticClass: "item",
        attrs: {
          placement: "right",
          "open-delay": 500,
          disabled: !record.valueTip
        }
      }, [_c("div", {
        staticClass: "record-tip",
        staticStyle: {
          "max-width": "500px"
        },
        attrs: {
          slot: "content"
        },
        slot: "content"
      }, [_vm._v(_vm._s(record.value))]), _c("div", {
        class: {
          "record-can-edit": !(row.isPrimaryDomain && record.type == "NS")
        },
        on: {
          mouseenter: function mouseenter($event) {
            return _vm.showRecordTip($event, record, "valueTip");
          },
          click: function click($event) {
            return _vm.recordEdit(row, record);
          }
        }
      }, [_vm._v(_vm._s(record.value))])])], 1)]), _c("div", {
        staticStyle: {
          width: "11%"
        }
      }, [_c("div", {
        staticClass: "record-wrap"
      }, [_c("div", {
        class: {
          "record-can-edit": !(row.isPrimaryDomain && record.type == "NS")
        },
        on: {
          click: function click($event) {
            return _vm.recordEdit(row, record);
          }
        }
      }, [_vm._v(_vm._s(record.weight))])])]), _c("div", {
        staticStyle: {
          width: "11%"
        }
      }, [_c("div", {
        staticClass: "record-wrap"
      }, [_c("div", {
        class: {
          "record-can-edit": !(row.isPrimaryDomain && record.type == "NS")
        },
        on: {
          click: function click($event) {
            return _vm.recordEdit(row, record);
          }
        }
      }, [_vm._v(_vm._s(record.ttl))])])]), _c("div", {
        staticStyle: {
          width: "13%",
          "text-align": "left"
        }
      }, [_c("div", {
        staticClass: "record-wrap"
      }, [_c("el-tooltip", {
        staticClass: "item",
        attrs: {
          placement: "right",
          "open-delay": 500,
          disabled: !record.hcTip
        }
      }, [_c("div", {
        staticClass: "record-tip",
        staticStyle: {
          "max-width": "500px"
        },
        attrs: {
          slot: "content"
        },
        slot: "content"
      }, [_vm._v(_vm._s(_vm.formatHc(record)))]), _c("div", {
        staticClass: "tag-hc",
        class: {
          "record-can-edit": !(row.isPrimaryDomain && record.type == "NS")
        },
        on: {
          mouseenter: function mouseenter($event) {
            return _vm.showRecordTip($event, record, "hcTip");
          },
          click: function click($event) {
            return _vm.recordEdit(row, record);
          }
        }
      }, [_c("span", {
        staticStyle: {
          width: "25px",
          display: "inline-block"
        }
      }, [_vm._v(_vm._s(record.isHc ? "ON" : "OFF"))]), _c("span", {
        staticClass: "hc-split"
      }, [_vm._v(" | ")]), _c("span", [_vm._v(_vm._s(record.priority == 1 ? _vm.$t("region.priority-secondary") : _vm.$t("region.priority-primary")))]), _c("span", {
        staticClass: "hc-split"
      }, [_vm._v(" | ")]), _c("span", [_vm._v(_vm._s(record.isHc && record.hc.tplId != 0 ? record.hc.tplName : "-"))])])])], 1)]), _c("div", {
        staticStyle: {
          width: "10%",
          "text-align": "center"
        }
      })]) : _vm._e(), record.isEdit && !(row.isPrimaryDomain && record.type == "NS") ? _c("div", {
        staticClass: "table-record-row"
      }, [_c("div", {
        staticStyle: {
          width: "2%"
        }
      }), _c("div", {
        staticStyle: {
          width: "2%"
        }
      }), _c("div", {
        staticStyle: {
          width: "15%"
        }
      }, [_c("div", {
        staticClass: "record-edit"
      }, [record.isEdit ? _c("el-cascader", {
        key: record.id,
        ref: _vm.cascaderRef(record),
        refInFor: true,
        staticClass: "recorder-group-panel",
        attrs: {
          options: _vm.viewOptions,
          disabled: row.isPrimaryDomain || _vm.isPtrZone,
          filterable: ""
        },
        on: {
          change: function change($event) {
            return _vm.handleViewChange(record);
          }
        },
        model: {
          value: record.lines,
          callback: function callback($$v) {
            _vm.$set(record, "lines", $$v);
          },
          expression: "record.lines"
        }
      }) : _vm._e()], 1)]), _c("div", {
        staticStyle: {
          width: "15%"
        }
      }, [_c("div", {
        staticClass: "record-edit"
      }, [_c("el-select", {
        attrs: {
          placeholder: _vm.$t("region.dlg-select-recordtype")
        },
        on: {
          change: function change(val) {
            _vm.handleRecorderType(val, record);
          }
        },
        model: {
          value: record.type,
          callback: function callback($$v) {
            _vm.$set(record, "type", $$v);
          },
          expression: "record.type"
        }
      }, _vm._l(_vm.getRecorderType(row.isPrimaryDomain, record), function (item) {
        return _c("el-option", {
          key: item.value,
          attrs: {
            label: item.value,
            value: item.value
          }
        }, [_c("span", {
          staticClass: "opt-value"
        }, [_vm._v(_vm._s(item.value))]), item.value == "SPF" ? _c("span", {
          staticClass: "opt-value"
        }, [_vm._v("（" + _vm._s(_vm.$t("region.recordtype-annotation-notsuggest")) + "）")]) : _vm._e(), _c("span", {
          staticClass: "opt-label"
        }, [_vm._v("-" + _vm._s(item.label))])]);
      }), 1)], 1)]), _c("div", {
        staticStyle: {
          width: "21%"
        }
      }, [_c("div", {
        staticClass: "record-edit"
      }, [_c("el-input", {
        ref: "recordValue__" + record.id,
        refInFor: true,
        attrs: {
          placeholder: record.valueTip || _vm.$t("region.dlg-input-value"),
          clearable: ""
        },
        model: {
          value: record.value,
          callback: function callback($$v) {
            _vm.$set(record, "value", typeof $$v === "string" ? $$v.trim() : $$v);
          },
          expression: "record.value"
        }
      })], 1)]), _c("div", {
        staticStyle: {
          width: "11%"
        }
      }, [_c("div", {
        staticClass: "record-edit"
      }, [_c("el-tooltip", {
        attrs: {
          content: _vm.$t("region.input-weight-tip"),
          placement: "top",
          effect: "light",
          "hide-after": 0,
          "open-delay": 500,
          disabled: record.type != "" && record.type != "A"
        }
      }, [_c("el-input", {
        attrs: {
          type: "number",
          min: "0",
          max: "10",
          placeholder: _vm.$t("region.dlg-input-weight"),
          disabled: record.type != "" && record.type != "A"
        },
        model: {
          value: record.weight,
          callback: function callback($$v) {
            _vm.$set(record, "weight", _vm._n($$v));
          },
          expression: "record.weight"
        }
      })], 1)], 1)]), _c("div", {
        staticStyle: {
          width: "11%"
        }
      }, [_c("div", {
        staticClass: "record-edit"
      }, [_c("el-tooltip", {
        attrs: {
          content: _vm.$t("region.input-ttl-tip"),
          placement: "top",
          effect: "light",
          "hide-after": 800,
          "open-delay": 500
        }
      }, [_c("el-input", {
        attrs: {
          type: "number",
          min: "60",
          max: "604800",
          placeholder: "TTL"
        },
        model: {
          value: record.ttl,
          callback: function callback($$v) {
            _vm.$set(record, "ttl", _vm._n($$v));
          },
          expression: "record.ttl"
        }
      })], 1)], 1)]), _c("div", {
        staticStyle: {
          width: "13%",
          "text-align": "left"
        }
      }, [_c("el-popover", {
        attrs: {
          placement: "bottom",
          width: "200",
          disabled: _vm.zoneStatus == 2
        }
      }, [_c("div", {
        staticClass: "hc-setting"
      }, [_c("div", [_c("el-select", {
        attrs: {
          placeholder: _vm.$t("region.dlg-select-priority")
        },
        model: {
          value: record.priority,
          callback: function callback($$v) {
            _vm.$set(record, "priority", $$v);
          },
          expression: "record.priority"
        }
      }, _vm._l(_vm.priorityOptions, function (item) {
        return _c("el-option", {
          key: item.value,
          attrs: {
            label: item.label,
            value: item.value
          }
        });
      }), 1)], 1), _c("div", [_c("label", [_vm._v(_vm._s(_vm.$t("region.sw-hc-tip")) + "：")]), _c("el-tooltip", {
        attrs: {
          content: _vm.$t("region.template-select-tip"),
          "open-delay": 500,
          disabled: record.type == "A"
        }
      }, [_c("div", {
        staticStyle: {
          display: "inline-block"
        }
      }, [_c("el-switch", {
        attrs: {
          "active-color": "#13ce66",
          disabled: _vm.zoneStatus == 2 || record.type != "A" && record.type != "AAAA"
        },
        model: {
          value: record.isHc,
          callback: function callback($$v) {
            _vm.$set(record, "isHc", $$v);
          },
          expression: "record.isHc"
        }
      })], 1)])], 1), _c("div", {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: record.isHc,
          expression: "record.isHc"
        }]
      }, [_c("el-select", {
        staticClass: "choose-tpl",
        attrs: {
          filterable: "",
          clearable: "",
          placeholder: _vm.$t("region.dlg-select-template")
        },
        on: {
          change: function change($event) {
            return _vm.handleHcSetting(record);
          }
        },
        model: {
          value: record.hc.tplId,
          callback: function callback($$v) {
            _vm.$set(record.hc, "tplId", $$v);
          },
          expression: "record.hc.tplId"
        }
      }, [_c("el-option", {
        key: "0",
        attrs: {
          label: _vm.$t("region.dlg-select-template"),
          value: 0
        }
      }), _vm._l(_vm.hcTplMap, function (value) {
        return _c("el-option", {
          key: value[0],
          attrs: {
            label: value[1].tplName,
            value: value[0]
          }
        });
      })], 2)], 1)]), _c("el-button", {
        staticClass: "hc-setting-btn",
        class: {
          "is-disabled": _vm.zoneStatus == 2
        },
        attrs: {
          slot: "reference",
          type: "text",
          disabled: _vm.zoneStatus == 2 || _vm.isPtrZone
        },
        slot: "reference"
      }, [_c("span", {
        staticClass: "hc-content",
        staticStyle: {
          width: "25px",
          display: "inline-block"
        }
      }, [_vm._v(_vm._s(record.isHc ? "ON " : "OFF"))]), _c("span", {
        staticClass: "hc-split"
      }, [_vm._v(" | ")]), _c("span", [_vm._v(_vm._s(record.priority == 1 ? _vm.$t("region.priority-secondary") : _vm.$t("region.priority-primary")))]), _c("span", {
        staticClass: "hc-split"
      }, [_vm._v(" | ")]), _c("span", [_vm._v(_vm._s(record.isHc && record.hc.tplId != 0 ? record.hc.tplName : "-"))]), _c("i", {
        staticClass: "el-icon-edit-outline el-icon--right"
      })])], 1)], 1), _c("div", {
        staticStyle: {
          width: "10%",
          "text-align": "center"
        }
      }, [_c("div", {
        staticClass: "record-edit"
      }, [_c("el-button", {
        key: record.id,
        staticClass: "btn-record-opr",
        attrs: {
          type: "danger",
          size: "mini",
          icon: "el-icon-minus",
          plain: "",
          circle: ""
        },
        on: {
          click: function click($event) {
            return _vm.deleteRow(row, ridx);
          }
        }
      })], 1)])]) : _vm._e()]);
    }), 0)]);
  }), 0), _c("el-pagination", {
    style: {
      float: "right",
      padding: "20px"
    },
    attrs: {
      "current-page": _vm.page,
      "page-size": _vm.pageSize,
      "page-sizes": [5, 10, 30, 50, 100],
      total: _vm.total,
      layout: "slot, sizes, prev, pager, next, jumper"
    },
    on: {
      "current-change": _vm.handleCurrentChangeWrap,
      "size-change": _vm.handleSizeChangeWrap
    }
  }, [_c("span", {
    staticClass: "record-total"
  }, [_vm._v(" 总计 "), _c("b", [_vm._v(_vm._s(_vm.total))]), _vm._v(" 条域名 ")])]), _c("el-backtop", {
    attrs: {
      target: ".main-right",
      "visibility-height": 200
    }
  }, [_c("i", {
    staticClass: "el-icon-caret-top"
  })])], 1), _c("div", [_c("el-dialog", {
    attrs: {
      "before-close": _vm.closeAddRemarkDialog,
      visible: _vm.addRemarkDialogFormVisible,
      title: _vm.$t("domain.dlg-record-remark"),
      width: "520px"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.addRemarkDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "remarkFormData",
    attrs: {
      model: _vm.remarkFormData,
      "label-position": "right"
    }
  }, [_c("el-form-item", [_c("el-col", {
    attrs: {
      span: 24
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: _vm.$t("domain.input-remark"),
      maxlength: "100",
      "show-word-limit": "",
      rows: "6"
    },
    model: {
      value: _vm.remarkFormData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.remarkFormData, "remark", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "remarkFormData.remark"
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeAddRemarkDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.fullscreenLoading,
      expression: "fullscreenLoading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    attrs: {
      type: "primary",
      disabled: _vm.isDisabledSaveBtn
    },
    on: {
      click: function click($event) {
        return _vm.saveAddRemarkDialog("remarkFormData");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)], 1), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeQueryDialog,
      visible: _vm.queryVisible,
      title: "24HRS Queries",
      width: "60%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.queryVisible = $event;
      }
    }
  }, [_c("HCharts", {
    attrs: {
      param: _vm.param
    }
  })], 1), _c("el-dialog", {
    attrs: {
      "before-close": _vm.closeCloneDialog,
      visible: _vm.cloneDialogFormVisible,
      title: _vm.$t("domain.clone-dlg-title"),
      width: "50%"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.cloneDialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "cloneFormData",
    attrs: {
      model: _vm.cloneFormData,
      "label-position": "right",
      "label-width": "80px",
      size: "mini"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "name",
      rules: [{
        required: true,
        message: _vm.$t("domain.checkdomain-error-empty"),
        trigger: "blur"
      }, {
        type: "string",
        message: _vm.$t("domain.checkdomain-error-invalid"),
        trigger: "blur",
        pattern: /^(?=^.{1,255}$)[a-zA-Z0-9_\*][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9_][-a-zA-Z0-9]{0,62})*$/
      }]
    }
  }, [_c("el-col", {
    attrs: {
      span: 14
    }
  }, [_c("el-input", {
    attrs: {
      clearable: "",
      placeholder: _vm.$t("domain.dlg-input-domain")
    },
    model: {
      value: _vm.cloneFormData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.cloneFormData, "name", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "cloneFormData.name"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v(_vm._s(_vm.zoneSuffixName))])], 2)], 1)], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeCloneDialog
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.cancel")))]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.fullscreenLoading,
      expression: "fullscreenLoading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    attrs: {
      type: "primary",
      disabled: _vm.isDisabledSaveBtn
    },
    on: {
      click: function click($event) {
        return _vm.saveCloneDialog("cloneFormData");
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("common.button.confirm")))])], 1)], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };